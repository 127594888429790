import { URL_BASE_POLIZARIO } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";

import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetLista = (validador1) => {
  return async (dispatch) => {
    dispatch(setLoadingPolizario(true));
    // const url = `${URL_BASE_POLIZARIO}/Elaguila/GetLista/${validador1}`;
     const url = `${URL_BASE_POLIZARIO}/Elaguila/GetByPolizaExacta/${validador1}`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeElAguilaLista(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get lista",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setVistaResultados(1));
    dispatch(setLoadingPolizario(false));
  };
};

export const startGetDetalle = (elaguilaID) => {
  return async (dispatch) => {
    dispatch(setLoadingPolizario(true));
    const url = `${URL_BASE_POLIZARIO}/Elaguila/GetDetalle/${elaguilaID}`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeElAguilaPoliza(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get detalle",
            `${result.error}`
          )
        )
      );
    }
    dispatch(setVistaResultados(2));
    dispatch(setLoadingPolizario(false));
  };
};

const storeElAguilaLista = (lista) => ({
  type: types.storeElAguilaLista,
  payload: lista,
});

const storeElAguilaPoliza = (poliza) => ({
  type: types.storeElAguilaPoliza,
  payload: poliza,
});

export const setClienteID = (clienteID) => ({
  type: types.setClienteID,
  payload: clienteID,
});

export const setLoadingPolizario = (state) => ({
  type: types.setLoadingPolizario,
  payload: state,
});

export const setVistaResultados = (vista) => ({
  type: types.setVistaResultados,
  payload: vista,
});
