/* eslint-disable */
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

import { Col_12_8_6_4 } from "../../../components/col/Col_12_8_6_4";
import { LabelValor } from "../../../components/label/LabelValor";
import { ColCampo } from "./ColCampo";
import { Secciones } from "./Secciones";
import { FormAddIncidencia } from "./FormAddIncidencia";


//////////////
export const Datos = () => {
  const {asistencia} = useSelector((state) => state.detalleReducer);
  //////////////
  return (
    <>
      <Row style={{marginBottom: 10}} gutter={[10, 10]}>
        <Col_12_8_6_4>
          <LabelValor label="ID" valor={asistencia.asistenciaID}/>
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Campaña"
            valor={asistencia.asegurado?.campania?.nombre}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Aperturó" valor={asistencia.usuario?.nombre}/>
        </Col_12_8_6_4>
      </Row>
      <Row style={{marginBottom: 10}} gutter={[10, 10]}>
        <Col_12_8_6_4>
          <LabelValor label="Nombre" valor={asistencia.nombreLlamada}/>
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Apellido paterno"
            valor={asistencia.apellidoPaternoLlamada}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Apellido materno"
            valor={asistencia.apellidoMaternoLlamada}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Teléfono" valor={asistencia.telefono}/>
        </Col_12_8_6_4>
      </Row>
      <Row style={{marginBottom: 10}} gutter={[10, 10]}>
        <Col_12_8_6_4>
          <LabelValor
            label="Nombre beneficiario"
            valor={asistencia.nombreBeneficiario}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Apellido paterno"
            valor={asistencia.apellidoPaternoBeneficiario}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Apellido materno"
            valor={asistencia.apellidoMaternoBeneficiario}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Email" valor={asistencia.email}/>
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Tipo de persona" valor={asistencia.tipoPersona}/>
        </Col_12_8_6_4>
      </Row>
      <Row style={{marginBottom: 10}} gutter={[10, 10]}>
        <Col_12_8_6_4>
          <LabelValor
            label="Validador 1"
            valor={asistencia.asegurado.validador1}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Validador 2"
            valor={asistencia.asegurado.validador2}
          />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Vigencia"
            valor={asistencia.asegurado.vigenciaTermino}
          />
        </Col_12_8_6_4>
        <Col xs={24} sm={16} md={12} xl={8}>
          <LabelValor
            label="Servicio"
            valor={`${asistencia.servicio?.tipoAsistencia.nombre} / ${asistencia.servicio?.nombre}`}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: 10}} gutter={[10, 10]}>
        <ColCampo campo={asistencia.campo1}/>
        <ColCampo campo={asistencia.campo2}/>
        <ColCampo campo={asistencia.campo3}/>
        <ColCampo campo={asistencia.campo4}/>
        <ColCampo campo={asistencia.campo5}/>
        <ColCampo campo={asistencia.campo6}/>
        <ColCampo campo={asistencia.campo7}/>
        <ColCampo campo={asistencia.campo8}/>
        <ColCampo campo={asistencia.campo9}/>
        <ColCampo campo={asistencia.campo10}/>
        <ColCampo campo={asistencia.campo11}/>
        <ColCampo campo={asistencia.campo12}/>
      </Row>
      <Row style={{marginBottom: 10}}>
        <Col span={24}>
          <LabelValor
            label="Direccion origen"
            valor={`${asistencia.direccionOrigen} (${asistencia.municipioOrigen}) | ${asistencia.latOrigen},${asistencia.lngOrigen} `}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: 10}}>
        <Col span={24}>
          {asistencia.direccionDestino !== "" && (
            <LabelValor
              label="Direccion destino"
              valor={`${asistencia.direccionDestino} (${asistencia.municipioDestino}) | ${asistencia.latDestino},${asistencia.lngDestino}  `}
            />
          )}
        </Col>
      </Row>
      <FormAddIncidencia/>
      <Secciones/>
    </>
  );
};
