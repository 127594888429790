import { Col, Divider, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import LabelTextarea from "../../../../components/input/LabelTextarea";
import {
  getDistanceBetweenPlaces,
  startPostProveedor,
} from "../../../../context/actions/detalleActions";
import { DatosSucursal } from "./DatosSucursal";
import BotonDivLeft2 from "../../../../components/Boton/BotonDivLeft2";
import LabelSelect from "../../../../components/select/LabelSelect";
import LabelNumber from "../../../../components/input/LabelNumber";
import LabelText from "../../../../components/input/LabelText";
import { cuentaEspecialGNPOpciones } from "../../../../constants/cons";
import {
  hdiTipoFechas,
  putNotification,
} from "../../../../context/actions/hdiActions";
import { AsignacionApi } from "../asignacionApi/AsignacionApi";

const localOpciones = [
  { texto: "local", valor: "local" },
  { texto: "carretero", valor: "carretero" },
];
/////
export const Forma = ({ handleVerMapa, handleHistorial }) => {
  const dispatch = useDispatch();

  const distanceInKmBetweenPlacesByApiGoogle = useSelector(
    (state) => state.detalleReducer.distanceInKmBetweenPlacesByApiGoogle
  );
  const {
    estatusFinalID,
    esGnpApi,
    latDestino,
    latOrigen,
    lngDestino,
    lngOrigen,
    folioGnp,
  } = useSelector((state) => state.detalleReducer.asistencia);
  useEffect(() => {
    void dispatch(
      getDistanceBetweenPlaces(
        { lat: parseFloat(latOrigen), lng: parseFloat(lngOrigen) },
        {
          lat: parseFloat(latDestino),
          lng: parseFloat(lngDestino),
        }
      )
    );
  }, [lngDestino, lngDestino, lngOrigen, latOrigen]);

  useEffect(() => {
    setDatos({ ...datos, KM: distanceInKmBetweenPlacesByApiGoogle });
  }, [distanceInKmBetweenPlacesByApiGoogle]);

  const { asistenciaID } = useParams();

  const [datos, setDatos] = useState({
    KM: distanceInKmBetweenPlacesByApiGoogle,
  });
  const [err, setErr] = useState("");

  const handleChange = ({ target }) => {
    setDatos({
      ...datos,
      [target.name]: target.value,
    });
  };

  const handleChangeSelect = (val) => {
    setDatos({
      ...datos,
      localCarretero: val,
    });
  };

  const handleCuentaEspecial = (val) => {
    setDatos({
      ...datos,
      cuentaEspecialGNP: val,
    });
  };

  const handleSwitch = (val, ele) => {
    setDatos({
      ...datos,
      [ele]: val,
    });
  };

  ///////////////////

  const handleClick = (estaAsignado) => {
    // Si el servicio es hdi hace la actualiación (verifica eso en la función)

    setErr("");

    if (
      estaAsignado &&
      (!datos.localCarretero || !datos.tiempoLlegada || !datos.costo)
    ) {
      setErr("Faltan datos");
      return;
    }

    const body = {
      asistenciaID,
      estaAsignado,
      esGnpApi,
      folioGnp,
      ...datos,
    };

    // console.log(body)
    dispatch(startPostProveedor(body)).then(() =>
      dispatch(putNotification(hdiTipoFechas.fechaAsignacion, datos))
    );
  };

  ///////////////////
  return (
    <div style={{ width: 350 }}>
      <div style={{ marginBottom: 10 }}>
        <Row style={{ marginBottom: 20 }}>
          <Col span={12}>
            <BotonDivLeft2
              onClick={handleHistorial}
              tituloBoton="Historial de Asignación"
            />
          </Col>
          <Col span={12}>
            <BotonDivLeft2
              onClick={handleVerMapa}
              tituloBoton="Asignar Proveedor (mapa)"
            />
          </Col>
        </Row>
      </div>

      <AsignacionApi />

      <Divider />
      <DatosSucursal />

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <Switch
            checkedChildren="Si pago directo"
            unCheckedChildren="No pago directo"
            onChange={(val) => handleSwitch(val, "esPagoDirecto")}
          />
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        {esGnpApi && (
          <Col span={12}>
            <LabelSelect
              label="Cuenta Especial GNP"
              name="cuentaEspecialGNP"
              value={datos.cuentaEspecialGNP}
              onChange={handleCuentaEspecial}
              opciones={cuentaEspecialGNPOpciones}
            />
          </Col>
        )}
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={12}>
          {" "}
          <LabelSelect
            label="Local / Carretero"
            name="localCarretero"
            onChange={handleChangeSelect}
            opciones={localOpciones}
          />
        </Col>

        <Col span={12}>
          <LabelNumber
            name="KM"
            label="KM"
            onChange={handleChange}
            value={datos.KM}
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            name="tiempoLlegada"
            value={datos.tiempoLlegada}
            label="Tiempo llegada (mins)"
            onChange={handleChange}
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            name="costo"
            label="Costo"
            onChange={handleChange}
            value={datos.costo}
          />
        </Col>
        <Col span={12} style={{ marginTop: "22px" }}>
          <LabelNumber
            name="costoCliente"
            label="Costo cliente"
            onChange={handleChange}
            value={datos.costoCliente}
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            name="costoAutorizado"
            label="Costo autorizado (excedente)"
            onChange={handleChange}
            value={datos.costoAutorizado}
          />
        </Col>
        <Col span={12}>
          <LabelText
            name="quienAutoriza"
            label="Quien autoriza"
            onChange={handleChange}
            value={datos.quienAutoriza}
          />
        </Col>
      </Row>

      <div
        style={{
          marginBottom: "22px",
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "1rem",
        }}
      >
        <div>
          <LabelTextarea
            name="movitoExcedente"
            value={datos.motivoExcedente}
            label="Comentario excedente"
            onChange={handleChange}
          />
        </div>

        <div>
          <LabelTextarea
            name="comentarios"
            value={datos.comentarios}
            label="Comentario de asignación"
            onChange={handleChange}
          />
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Row gutter={10}>
          <Col>
            <BotonSubmit
              disabled={estatusFinalID !== 1}
              onClick={() => handleClick(0)}
              tituloBoton="No asignar"
              icon={<CloseCircleOutlined />}
            />
          </Col>
          <Col>
            <BotonSubmit
              disabled={estatusFinalID !== 1}
              onClick={() => handleClick(1)}
              tituloBoton="Asignar"
              icon={<CheckCircleOutlined />}
            />
          </Col>
          <Col>
            <div style={{ marginTop: 5, color: "#ca2228" }}>{err}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
