import CardPage from "../../layout/CardPage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getConditions,
  getPDFUrl,
  openModalMain,
  setCurrentPDFId,
  setPDFInScreen
} from "../../context/actions/libraryActions";
import { Button, Tree } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import { ModalPdf } from "./components/ModalPdf";
import ModalAgregarPdf from "./components/ModalAgregarCondicion";
import { ModalEliminarPDF } from "./components/ModalEliminarPDF";

export const Library = () => {

  const [open, setOpen] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const {DirectoryTree} = Tree

  useEffect(() => {
    void dispatch(getConditions())
  }, []);

  const conditionsList = useSelector(state => state.libraryReducer.conditions)

  const itemsDirectory = conditionsList.map(item =>
    (
      {
        title: item.nombreComercial,
        clickable: false,
        selectable: false,
        key: item.key,
        children: item.condiciones.map(condition => ({
          icon: <FilePdfOutlined/>,
          title: condition.nombreArchivo,
          key: condition.id
        }))
      }
    )
  )

  const onSelect = (a, b) => {
    void dispatch(setPDFInScreen(b.node.title))
    void dispatch(setCurrentPDFId(b.node.key))
    void dispatch(getPDFUrl(b.node.key))
    void dispatch(openModalMain(true))
  }

  return (
    <div>
      <CardPage titulo={"Biblioteca"} tituloBoton={'Agregar'} accionBoton={() => setOpen(true)} >
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={() => setOpenDeleteModal(true)} type={'text'} danger> Eliminar </Button>
        </div>
        <DirectoryTree
          multiple
          defaultExpandAll
          onSelect={(a, b) => onSelect(a, b)}
          // onExpand={onExpand}
          treeData={itemsDirectory}
        />
      </CardPage>

      <ModalPdf/>

      <ModalAgregarPdf open={open} setOpen={setOpen}/>

      <ModalEliminarPDF open={openDeleteModal} setOpen={setOpenDeleteModal}/>
    </div>
  )
}