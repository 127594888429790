import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { startLoadCampaniaData } from "../../../../context/actions/capturaActions";
import { useClienteCampania } from "../../../../hooks/useClienteCampania";
import CardPage from "../../../../layout/CardPage";
import { BotonFase1 } from "./BotonFase1";
import Fase1Forma from "./Fase1Forma";
export const Fase1 = () => {
  const dispach = useDispatch();
  const history = useHistory();
  const { campaniaID } = useParams();
  const { clienteNombre, campaniaNombre } = useClienteCampania(campaniaID);
  useEffect(() => {
    dispach(startLoadCampaniaData());
  }, []);

  return (
    <CardPage
      titulo={`${clienteNombre} / ${campaniaNombre}`}
      tituloBoton="Regresar"
      accionBoton={() => history.push("/crearServicio")}
    >
      <Fase1Forma />  
       <BotonFase1 /> 
    </CardPage>
  );
};
