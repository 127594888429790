import { Col, Row, Tooltip } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelText from "../../../../components/input/LabelText";
import { MyRow } from "../../../../components/row/MyRow";
import LabelSelect from "../../../../components/select/LabelSelect";
import { TipoPersonaSelect } from "../../../../components/select/TipoPersonaSelect";
import { CLIENTES_IDS } from "../../../../constants/cons";
import { setCaptura } from "../../../../context/actions/capturaActions";
import { serviciosTextoValor } from "../../../../utils/serviciosTextoValor";
import { tiposTextoValor } from "../../../../utils/tiposTextoValor";
import { Polizario } from "./polizario/Polizario";
import { PolizarioQualitas } from "./PolizarioQualitas/PolizarioQualitas";

export default function Fase1Forma() {
  const dispatch = useDispatch();
  const [opcionesServicios, setOpcionesServicios] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { tipos, serviciosFinal } = useSelector(
    (state) => state.asistenciasReducer.servicios
  );
  const opciones = tiposTextoValor(tipos || []);

  const {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    nombreBeneficiario,
    apellidoPaternoBeneficiario,
    apellidoMaternoBeneficiario,
    telefono,
    tipoPersona,
    validador1,
    validador2,
    numeroReporteHdi,
    email,
  } = useSelector((state) => state.asistenciasReducer.captura);

  const { clienteID } = useSelector((state) => state.polizarioReducer);
  const handleChange = ({ target }) => {
    const body = { [target.name]: target.value };
    dispatch(setCaptura(body));
  };

  const changeTipoAsistencia = (tipoAsistenciaID) => {
    const nuevos = serviciosFinal.filter(
      (s) => s.tipoAsistenciaID === tipoAsistenciaID
    );
    const salida = serviciosTextoValor(nuevos);
    setOpcionesServicios(salida);
  };

  const changeSelect = (val) => {
    const body = { servicioID: val };
    dispatch(setCaptura(body));
  };

  const changeTipoPersona = (val) => {
    const body = { tipoPersona: val };
    dispatch(setCaptura(body));
  };

  /////////////////////////
  return (
    <>
      <Row gutter={10}>
        <Col span={16}>
          <div className="subTitulo">Datos Generales</div>
          <MyRow>
            <Col span={8}>
              <LabelText
                label="Nombre reporta"
                onChange={handleChange}
                name="nombre"
                value={nombre}
              />
            </Col>
            <Col span={8}>
              <LabelText
                label="Apellido paterno"
                onChange={handleChange}
                name="apellidoPaterno"
                value={apellidoPaterno}
              />
            </Col>
            <Col span={8}>
              <LabelText
                label="Apellido materno"
                onChange={handleChange}
                name="apellidoMaterno"
                value={apellidoMaterno}
              />
            </Col>
            <Col span={8}>
              <LabelText
                label="Nombre beneficiario"
                onChange={handleChange}
                name="nombreBeneficiario"
                value={nombreBeneficiario}
              />
            </Col>
            <Col span={8}>
              <LabelText
                label="Apellido paterno"
                onChange={handleChange}
                name="apellidoPaternoBeneficiario"
                value={apellidoPaternoBeneficiario}
              />
            </Col>
            <Col span={8}>
              <LabelText
                label="Apellido materno"
                onChange={handleChange}
                name="apellidoMaternoBeneficiario"
                value={apellidoMaternoBeneficiario}
              />
            </Col>
            <Col span={8}>
              <LabelText
                label="Teléfono"
                onChange={handleChange}
                name="telefono"
                value={telefono}
              />
            </Col>
            <Col span={8}>
              <LabelText
                label="Email"
                onChange={handleChange}
                name="email"
                value={email}
              />
            </Col>
            <Col span={8}>
              <TipoPersonaSelect
                value={tipoPersona}
                onChange={changeTipoPersona}
              />
            </Col>
          </MyRow>

          <MyRow>
            <Col span={8}>
              <LabelText
                disabled
                label="Valdiador 1"
                onChange={handleChange}
                name="validador1"
                value={validador1}
              />
            </Col>
            <Col span={8}>
              <LabelText
                label="Valdiador 2"
                onChange={handleChange}
                name="validador2"
                value={validador2}
              />
            </Col>
          </MyRow>
          <MyRow>
            <Col span={8}>
              <LabelSelect
                label="Tipo de asistencia"
                onChange={changeTipoAsistencia}
                opciones={opciones}
                name="tipoAsistencia"
              />
            </Col>
            <Col span={8}>
              <LabelSelect
                disabled={opcionesServicios.length === 0}
                label="Servicio"
                onChange={changeSelect}
                opciones={opcionesServicios}
                name="servicioID"
              />
            </Col>

            {CLIENTES_IDS.HDI === clienteID && (
              <>
                <Col span={8}>
                  <Tooltip
                    color="red"
                    open={numeroReporteHdi && numeroReporteHdi?.length != 7}
                    title={"El número de reporte debe constar de 7 dígitos"}
                  >
                    <LabelText
                      label="No reporte HDI"
                      onChange={handleChange}
                      name="numeroReporteHdi"
                      value={numeroReporteHdi}
                    />
                  </Tooltip>
                </Col>
              </>
            )}
          </MyRow>
        </Col>
        {(CLIENTES_IDS.EL_AGUILA === clienteID ||
          CLIENTES_IDS.EL_AGUILADev === clienteID) && (
          <Col span={8}>
            {/* SOLO PARA EL AGUILA */}
            <Polizario />
          </Col>
        )}
      </Row>
    </>
  );
}
