import { Alert, Button, Col, Modal, Row, Tooltip } from "antd";
import LabelText from "../../../../components/input/LabelText";
import { CopyOutlined } from "@ant-design/icons";
import { useElAguilaForma } from "./useElAguilaForma";

export const BotonElAguila = () => {
  const { copiado, form, handleChange, submit, copy, open, setOpen, limpiar } =
    useElAguilaForma();
  //
  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        danger
        size="small"
        type="link"
        style={{ marginTop: 10 }}
      >
        Escalar Siniestro
      </Button>
      <Modal
        footer={[
          <Button onClick={limpiar}>Limpiar</Button>,
          <Button onClick={() => setOpen(false)}>Cerrar Ventana</Button>,
        ]}
        onCancel={() => setOpen(false)}
        onOk={submit}
        open={open}
        title="Agregar Resgistro - Escalación de Siniestro"
      >
        <div style={{ marginBottom: 10 }}>
          <LabelText
            label="Poliza ID"
            onChange={handleChange}
            name="polizaID"
            value={form.polizaID}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <LabelText
            label="Nombre"
            onChange={handleChange}
            name="aseguradoNombre"
            value={form.aseguradoNombre}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <LabelText
            label="Teléfono"
            onChange={handleChange}
            name="aseguradoTelefono"
            value={form.aseguradoTelefono}
          />
        </div>
        <div style={{ textAlign: "center", marginBottom: 10 }}>
          <Button disabled={form.ok} type="primary" onClick={submit}>
            Guardar Datos
          </Button>
        </div>
        <div style={{ height: 55 }}>
          {form.err && <Alert type="warning" message={form.err} showIcon />}
          {form.ok && (
            <Alert type="success" message="Datos Guardados" showIcon />
          )}
        </div>
        <Row>
          <Col span={3} style={{ paddingTop: 15, textAlign: "center" }}>
            <Tooltip title="Copiar Datos">
              <CopyOutlined
                onClick={copy}
                className="eyeIcon"
                style={{ color: !copiado ? "orange" : "#999", fontSize: 30 }}
              />
            </Tooltip>
          </Col>
          <Col span={10}>
            <div>
              <span style={{ color: "orange" }}>Poliza:</span> {form.polizaID}
            </div>
            <div>
              <span style={{ color: "orange" }}>Nombre: </span>
              {form.aseguradoNombre}
            </div>
            <div>
              <span style={{ color: "orange" }}>telefono: </span>
              {form.aseguradoTelefono}
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
