import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import BotonDivLeft2 from "../../../../../components/Boton/BotonDivLeft2";
import { startGetLista } from "../../../../../context/actions/polizarioActions";
import { DetallePoliza } from "./DetallePoliza";
import { ListaPolizas } from "./ListaPolizas";

export const Polizario = () => {
  const dispatch = useDispatch();
  const [txt, setTxt] = useState("");
  const { validador1 } = useSelector(
    (state) => state.asistenciasReducer.captura
  );
  const { vistaResultados } = useSelector((state) => state.polizarioReducer);

  useEffect(() => {
    dispatch(startGetLista(validador1));
  }, []);

  //
  return (
    <>
      <div className="subTitulo">Polizario El Águila</div>

      {vistaResultados === 1 && <ListaPolizas />}
      {vistaResultados === 2 && <DetallePoliza />}
    </>
  );
};
