import { useState } from "react";
import { post } from "../../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "../../../../context/actions/notificationActions";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../../../utils/composeNotification";
import { URL_BASE_EscalamientoElAguila } from "../../../../constants/url";
import { esSoloNumero } from "../../../../utils/esSoloNumero";

//
export const useElAguilaForma = () => {
  const { nombre } = useSelector((state) => state.userReducer.user);
  const [copiado, setCopaido] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [open, setOpen] = useState(false);

  //
  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  //
  const limpiar = () => setForm({});
  //
  const submit = async () => {
    const val = valida();
    if (val.ok !== true) {
      setForm({ ...form, err: val.mensaje });
      return;
    }

    //
    const result = await post(URL_BASE_EscalamientoElAguila, {
      ...form,
      quienCaptura: nombre,
    });
    if (result.code === 0) {
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(notification(composeErrorNotification("Error", result.error)));
    }
    setForm({ ...form, err: null, ok: true });
  };

  //
  const valida = () => {
    if (!form.polizaID) return { ok: false, mensaje: "La póliza es necesaria" };
    if (!form.aseguradoNombre)
      return { ok: false, mensaje: "El nombre es necesario" };

    if (!form.aseguradoTelefono)
      return { ok: false, mensaje: "El telefono es necesario" };
    else if (!esSoloNumero(form.aseguradoTelefono))
      return { ok: false, mensaje: "El telefono debe ser solo números" };
    else if (form.aseguradoTelefono.length !== 10)
      return { ok: false, mensaje: "El telefono es de 10 dígitos" };

    return { ok: true };
  };

  //
  const copy = () => {
    const salida = `Escalamiento de Siniestro\n\nPoliza: ${form.polizaID} \nNombre: ${form.aseguradoNombre} \nTelefono: ${form.aseguradoTelefono}`;
    navigator.clipboard.writeText(salida);
    setCopaido(true);
    setTimeout(() => {
      setCopaido(false);
    }, 4000);
  };

  //
  return { copiado, form, handleChange, submit, copy, open, setOpen, limpiar };
};
