import React, { useState } from 'react';
import { Button, Form, Input, message, Modal, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { post } from "../../../utils/fetch";
import { URL_BASE } from "../../../constants/url";
import { useDispatch, useSelector } from "react-redux";
import { getConditions } from "../../../context/actions/libraryActions";

const rules = [{required: true, message: "Campo requerido"}]
const ModalAgregarPdf = ({open, setOpen }) => {
  const conditionsList = useSelector(state => state.libraryReducer.conditions)
  const dispatch = useDispatch()
  const optionsClienteId = conditionsList.map(item => ({label: item.nombreComercial, value: item.key}))
  const [fileList, setFileList] = useState(null)
  const [form] = Form.useForm();
  const props = {
    onRemove: (file) => {
      setFileList([])
      form.setFieldValue('file', '')

    },
    beforeUpload: (file) => {
      setFileList([file])
      return false;
    },
    fileList,
  };
  const onSubmit = async (values) => {
    const url = `${URL_BASE}/api/Clientes/UploadCondiciones/${values.clienteId}`
    if (values.file.file.status === 'removed') {
      // retornar un error de que no se ha seleccionado un archivo
      message.error('No se ha seleccionado un archivo')
      return false
    }
    const formData = new FormData()
    console.log(values.nombreArchivo)
    console.log(fileList[0])
    formData.append('nombreArchivo', values.nombreArchivo)
    formData.append('file', fileList[0])

    await post(url, formData).then(e => {
      if (e.code !== -1) {
        message.success('Archivo subido correctamente')
        setOpen(false)
        void dispatch(getConditions())
        return
      }
      message.error('Error al subir el archivo')
    })

  }
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onSubmit}
      >
        <Form.Item name={'clienteId'} rules={rules} label={"Cliente"}>
          <Select options={optionsClienteId} placeholder={'Seleccione un cliente'}/>
        </Form.Item>
        <Form.Item name={'nombreArchivo'} rules={rules} label={'Nombre archivo'}>
          <Input placeholder={'Nombre del archivo'}/>
        </Form.Item>
        <Form.Item name={'file'} rules={rules} label={"Archivo"}>
          <Upload {...props} maxCount={1} accept={'.pdf'}>
            <Button icon={<UploadOutlined/>}>Select File</Button>
          </Upload>
        </Form.Item>
        <Button htmlType={'submit'} type={'primary'}>Agregar</Button>
      </Form>

    </Modal>

  );
};

export default ModalAgregarPdf;
