import { Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BotonSubmit from "../../../../components/Boton/BotonSubmit";
import { CLIENTES_IDS } from "../../../../constants/cons";
import {
  avanzaStep2,
  startCreaAsistenciaInicial,
} from "../../../../context/actions/capturaActions";
import { RequiereValidacion } from "./polizario/RequiereValidacion";

/////////////
export const BotonFase1 = () => {
  const dispatch = useDispatch();
  const { campaniaID } = useParams();
  const [err, setErr] = useState("");
  const { elAguilaAuth } = useSelector((state) => state.asistenciasReducer);
  const { elAguilaLista } = useSelector((state) => state.polizarioReducer);

  const {
    asistenciaID,
    servicioID,
    tipoPersona,
    validador2,
    numeroReporteHdi,
  } = useSelector((state) => state.asistenciasReducer.captura);

  const { clienteID } = useSelector((state) => state.polizarioReducer);
  const handleClick = () => {
    setErr("");
    if (!servicioID || !tipoPersona || !validador2 || validador2 === "") {
      setErr("Faltan datos");
      return;
    }

    if (clienteID === CLIENTES_IDS.HDI) {
      if (!numeroReporteHdi) {
        setErr("Falta reporte HDI");
        return;
      }
    }

    dispatch(startCreaAsistenciaInicial(campaniaID));
    dispatch(avanzaStep2());
  };

  if (
    clienteID === CLIENTES_IDS.EL_AGUILA ||
    clienteID === CLIENTES_IDS.EL_AGUILADev
  ) {
    if (elAguilaAuth !== true) {
      if (elAguilaLista.length === 0) return <RequiereValidacion tipo={1} />;
      else if (elAguilaLista[0].cobertura !== "S")
        return <RequiereValidacion tipo={2} />;
    }
  }

  //////////
  return (
    <Row>
      <Col span={5}>
        <BotonSubmit
          disabled={asistenciaID !== undefined}
          tituloBoton="Crear Servicio"
          onClick={handleClick}
        />
      </Col>
      <Col span={3} style={{ paddingTop: 4 }}>
        <span style={{ color: "#ca2228" }}>{err}</span>
      </Col>
      <Col span={7}>
        {asistenciaID && (
          <div className="folioServicio">Folio: {asistenciaID}</div>
        )}
      </Col>
    </Row>
  );
};
