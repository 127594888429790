import React from "react";
import { HomeOutlined, NodeIndexOutlined } from "@ant-design/icons";

export const LocalCarretero = ({ localCarretero, estatusID }) => {

  if (estatusID < 3) return <></>;
  return (
    <div style={{ textAlign: "center" }}>
      {localCarretero === "carretero" ? (
        <NodeIndexOutlined style={{ fontSize: 25, color: "#999" }} />
      ) : (
        <HomeOutlined style={{ fontSize: 25, color: "#999" }} />
      )}
    </div>
  );
};
