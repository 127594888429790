import React from "react";
import { useSelector } from "react-redux";
import { RenglonCita } from "../RenglonCita";
import { RenglonSemaforo } from "../RenglonSemaforo";
import { TituloSemaforo } from "../TituloSemaforo";

export const Citas = () => {
    const { asistenciasCitas } = useSelector((state) => state.semaforoReducer);

    return (
        <>
            <TituloSemaforo titulo="Citas Hoy" count={asistenciasCitas.length} />
            {asistenciasCitas.map((a, i) => (
                <RenglonCita
                    key={i}
                    asistencia={a}
                    fecha={a.fechaLlamadaIniciada}
                />
            ))}
        </>
    );
};
