import { Col, Divider, Input, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BotonDiv from "../../../components/Boton/BotonDiv";
import { ErrorCampo } from "../../../components/error/ErrorCampo";
import {
  setCaptura,
  startLoadValidadorInfo,
} from "../../../context/actions/capturaActions";
import { Resultados } from "./Resultados";

export const BuscaValidador = ({campaniaID }) => {
  const [show, setShow] = useState(false);


  const dispatch = useDispatch();

  const {nombre, apellidoPaterno, telefono, validador1} = useSelector(
    (state) => state.asistenciasReducer.captura
  );

  const {nombreErr, apellidoPaternoErr, telefonoErr, validador1Err} =
    useSelector((state) => state.erroresReducer.erroresBuscaValidador);
  //
  const handleChange = ({target}) => {
    const body = {[target.name]: target.value};
    dispatch(setCaptura(body));
  };

  //
  const handleClick = async () => {
    dispatch(startLoadValidadorInfo(validador1, campaniaID)).then(val => {
      if (val) setShow(true);
    });

  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={8}>
          <Input
            name="nombre"
            onChange={handleChange}
            placeholder="nombre reporta"
            value={nombre}
          />
          <ErrorCampo error={nombreErr}/>
        </Col>
        <Col span={8}>
          <Input
            onChange={handleChange}
            name="apellidoPaterno"
            value={apellidoPaterno}
            placeholder="apellido paterno"
          />
          <ErrorCampo error={apellidoPaternoErr}/>
        </Col>
        <Col span={8}>
          <Input
            onChange={handleChange}
            value={telefono}
            name="telefono"
            placeholder="telefono"
          />
          <ErrorCampo error={telefonoErr}/>
        </Col>
        <Col span={12}>
          <Input
            onChange={handleChange}
            name="validador1"
            value={validador1}
            placeholder="validador 1"
          />
          <ErrorCampo error={validador1Err}/>
        </Col>
        <Col style={{paddingTop: 3}}>
          <BotonDiv onClick={handleClick} tituloBoton="Buscar"/>
        </Col>
      </Row>

      {show && (
        <>
          <Divider/>
          <Resultados campaniaID={campaniaID}/>
        </>
      )}

    </>
  );
};
