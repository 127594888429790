import { Alert, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { clearProveedorGeos, startLoadHistorialAsignacion, } from "../../../../context/actions/detalleActions";
import { DatosProveedor } from "./DatosProveedor";
import { Forma } from "./Forma";
import { HistorialAsignacion } from "./HistorialAsignacion";

import { MapaProveedores } from "./MapaProveedores";
import { DrawerDesgloseCostos } from "./DrawerDesgloseCostos";

/////
export const Proveedor = () => {
  const dispatch = useDispatch();
  const {asistenciaID} = useParams();
  const {estatusID} = useSelector((state) => state.detalleReducer.asistencia);
  const [openDrawer, setOpenDrawer] = useState(false)
  const [show, setShow] = useState(false);
  const [showHistorial, setShowHistorial] = useState(false);

  const verMapaForma = () => {
    setShow(true);
    dispatch(clearProveedorGeos());
  };

  const handleHistorial = () => {
    setShowHistorial(true);
    dispatch(startLoadHistorialAsignacion(asistenciaID));
  };


  //////
  const handleDesgloseCostos = () => {
    setOpenDrawer(true)
  }
  if (estatusID < 2)
    return (
      <Alert
        message="Avance estatus para seleccionar proveedor"
        type="warning"
      />
    );

  return (
    <Row gutter={10}>
      <Col span={7}>
        {estatusID === 2 ? (
          <Forma
            handleHistorial={handleHistorial}
            handleVerMapa={verMapaForma}
          />
        ) : (
          <DatosProveedor
            handleHistorial={handleHistorial}
            handleVerMapa={verMapaForma}
            handleDesgloseCostos={handleDesgloseCostos}
          />
        )}
      </Col>
      <Col span={17}>{show && <MapaProveedores/>}</Col>


      <Modal
        title="Historial de Asignación"
        open={showHistorial}
        onOk={() => setShowHistorial(false)}
        onCancel={() => setShowHistorial(false)}
      >
        <HistorialAsignacion/>
      </Modal>
    <DrawerDesgloseCostos setOpen={setOpenDrawer} open={openDrawer}/>
    </Row>
  );
};
