import { Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { startValidaSupervisorElAguila } from "../../../../../context/actions/asistenciasActions";

export const RequiereValidacion = ({ tipo }) => {
  const dispatch = useDispatch();
  const [claveAutorizacion, setClaveAutorizacion] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      dispatch(startValidaSupervisorElAguila(claveAutorizacion));
    }
  };

  const texto =
    tipo === 1
      ? "Póliza no encontrada en polizario del "
      : "Poliza sin cobertura en polizario del ";
  return (
    <div style={{ padding: 10, backgroundColor: "#ffe6ed" }}>
      <Row gutter={20}>
        <Col>
          {texto}
          <span style={{ color: "orange", fontWeight: 600 }}> El Águila</span>,
          requiere validación
        </Col>
        <Col>
          <Input.Password
            style={{ width: 130 }}
            onKeyDown={handleKeyDown}
            vaalue={claveAutorizacion}
            onChange={({ target }) => setClaveAutorizacion(target.value)}
            placeholder="código supervisor"
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
};
