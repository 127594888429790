import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  startGetAsistenciasCitas,
  startGetServicioParaSemaforo,
} from "../../context/actions/semaforoActions";
import { BuscandoProveedor } from "./buscandoProveedor/BuscandoProveedor";
import { CapturandoDatos } from "./capturandoDatos/CapturandoDatos";
import { Citas } from "./citas/Citas";
import { PorCerrarServicio } from "./porCerrarServicio/PorCerrarServicio";
import { PorContactarUsuario } from "./porContactarUsuario/PorContactarUsuario";

export const Semaforo = () => {
  const dispatch = useDispatch();

  //const { connection } = useSelector((state) => state.chatReducer);

  useEffect(() => {
   // connection?.send("LoadChatsCabina");
    dispatch(startGetServicioParaSemaforo());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(startGetServicioParaSemaforo());
      dispatch(startGetAsistenciasCitas());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Row gutter={10}>
      <Col span={6}>
        <CapturandoDatos />
        <div style={{ marginTop: 10 }}>
          <Citas />
        </div>
      </Col>
      <Col span={6}>
        <BuscandoProveedor />
      </Col>
      <Col span={6}>
        <PorContactarUsuario />
      </Col>
      <Col span={6}>
        <PorCerrarServicio />
      </Col>
    </Row>
  );
};
