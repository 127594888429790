import { Button, Divider, Form, message, Select } from "antd";
import { useParams } from "react-router-dom";
import { post } from "../../../utils/fetch";
import { URL_BASE } from "../../../constants/url";
import { useDispatch, useSelector } from "react-redux";
import { LabelValor } from "../../../components/label/LabelValor";
import { DayjsConfig } from "../../../utils/configDayjs";
import { startLoadAsistencia } from "../../../context/actions/detalleActions";

const options = [
  {label: 'Pendiente Autorización', value: 'Pendiente Autorización'},
  { label: 'Zona roja', value: 'Zona roja'},
  {label: 'Servicio especial', value: 'Servicio especial'},
  {label: 'Usuario no encontrado en lugar', value: 'Usuario no encontrado en lugar'},
  {label: 'Datos geolocalización incorrectos', value: 'Datos geolocalización incorrectos'},
  {label: 'Problemas climáticos', value: 'Problemas climáticos'},
  {label: 'Plantón , marcha', value: 'Plantón, marcha'},
  {label: 'Calle cerrada por reparación', value: 'Calle cerrada por separación'}

]

const format = DayjsConfig('DD-MMM-YYYY')
export const FormAddIncidencia = () => {

  const {asistenciaID} = useParams()
  const dispatch = useDispatch()

  const detalles = useSelector(state => state.detalleReducer.asistencia)
  const onFinish = async (values) => {
    const {MotivoIncidencia} = values
    const body = {
      asistenciaID,
      MotivoIncidencia
    }
    const result = await post(`${URL_BASE}/api/asistenciaIncidencia`, body)
    if (result.code === 0) {
      message.success(result.data)
      void dispatch(startLoadAsistencia(asistenciaID))
    } else {
      message.error(result.error)
    }
  }

  return (
    <div>

      <Divider type={'horizontal'}/>

      {!detalles.asistenciaIncidencia
        ? <Form
          onFinish={(values) => onFinish(values)}
          style={{display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px'}}
          layout={'vertical'}
        >
          <Form.Item name={'MotivoIncidencia'} label={'Motivo de incidencia'}>
            <Select options={options} style={{width: '300px'}} size={'small'}/>
          </Form.Item>

          <Button type={'primary'} size={'small'} htmlType={'submit'}>Agregar</Button>


        </Form>
        : <LabelValor
          label={'Incidencia'}
          style={{marginBottom: '10px'}}
          valor={`${format(detalles.asistenciaIncidencia.fechaIncidencia)}  - ${detalles.asistenciaIncidencia.usuarioIncidenciaNombre}:  ${detalles.asistenciaIncidencia.motivoIncidencia}`}/>}

    </div>
  )
}